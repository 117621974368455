import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';
import windowGlobal from '../utils/window-global';

const API_SERVER = process.env.NODE_ENV === 'production' ? 
	'https://api.danceableapp.com' :
	'http://localhost:3030';

const socket = io(API_SERVER);
const client = feathers();

client.configure(socketio(socket, {
	pingInterval: 10000,
	pingTimeout: 50000
}));

if(windowGlobal && windowGlobal.localStorage) {
	client.configure(authentication({
		storage: windowGlobal.localStorage
	}));
} else {
	client.configure(authentication());
}

if(!client.socket)
	client.socket = socket;

client.on('authenticated', data => {
	client.authenticated = data;
	// console.log("[feathers.client.authenticated]  ", data);  
});

client.on('logout', data => {
	// console.log("[feathers.client.logout]  ", data);
	client.authenticated = null;
});

// {
// 	const _authenticate = client.authenticate.bind(client);
// 	const windowGlobal = typeof window !== 'undefined' && window;

// 	client.authenticate = args => {
// 		if(!args) {
// 			let stored;
// 			if(windowGlobal.localStorage) {
// 				try {
// 					stored = JSON.parse(atob(windowGlobal.localStorage.getItem("feathers:lastAuth")));
// 				} catch(e) {}
// 			}

// 			console.log("[client.authenticate] using stored:", stored);

// 			return _authenticate(stored);
// 		} else {
// 			windowGlobal.localStorage.setItem("feathers:lastAuth", btoa(JSON.stringify(args)));

// 		console.log("[client.authenticate] new auth:", args);
// 			return _authenticate(args);
// 		}
// 	};
// }

export default client;
export { client, API_SERVER, socket };
