import currentBuildVersion from './build-ver';
import windowGlobal from './window-global';
import $ from 'jquery';

const verCheckHost = process.env.NODE_ENV === 'production' ? 
	'https://danceableapp.com' :
	'http://localhost:3030';



class VersionChecker {
	constructor() {
		// check every X ms (currently: every 1 minute)
		this.versionCheckInterval = 1000 //ms
			* 60 // seconds
			;
	}

	start() {
		if(!windowGlobal) {
			console.log("No windowGlobal, not starting version checker");
		}
		this.tid = setInterval(() => this.checkVersion(), this.versionCheckInterval);
		// console.log("VersionChecker started");
		this.checkVersion();
	}

	stop() {
		clearInterval(this.tid);
	}

	checkVersion() {
		if(typeof($.getJSON) !== 'function')
			return;

		if(process.env.NODE_ENV !== 'production') {
			console.log("Not checking build ver in development");
			return;
		}
		
		return new Promise((resolve, reject) => {
			const timeoutId = setTimeout( () => {
				reject("timeout");
			}, 5000); // timeout after X sec

			$.getJSON(verCheckHost + '/build-ver.json?_='+(new Date().getTime())).then(latestVer => {
				clearTimeout(timeoutId);

				// console.warn('[checkVersion] checked ',(verCheckHost || '[origin host]'),', got: ', {latestVer, currentBuildVersion});

				// // NOTE: REMOVE FOR PRODUCTION
				// // NOTE: REMOVE FOR PRODUCTION
				// currentBuildVersion.sha1 = new Date().getTime() + 'FAKESHA1';
				// latestVer.sha1 = new Date().getTime() + 'INSTALLME';
				// // NOTE: REMOVE FOR PRODUCTION
				// // NOTE: REMOVE FOR PRODUCTION

				if( latestVer.sha1 != currentBuildVersion.sha1) {
					console.warn("New version of software found on server, installing updates and gently reloading:" + JSON.stringify({serverVer:latestVer,currentBuildVersion}));

					window.location.reload();

					resolve();
				}
				else {
					// console.log("No new version");
					resolve();
				}
			})
			.fail((error) => {
				console.error("Error checking for updates:" + (error.message ? error.message : JSON.stringify(error)));
				reject(error);
			});
		});
	}
}


const versionChecker = new VersionChecker();

export default versionChecker;