import React from 'react';
import Img from "gatsby-image";
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

export default function SocialIcons(props) {
	return (
		<StaticQuery
			query={graphql`
				query {
					file(relativePath: { regex: "/dlogo-310.png/" }) {
						childImageSharp {
							fixed(width: 48, height: 48) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			`}
			render={data => (
				<Link to="/" className={props.className || "logo-image"}>
					{/* <Img fixed={data.file.childImageSharp.fixed}/> */}
					Danceable
				</Link>
			)}
		/>
	)
}
