import React from 'react'
import { Link } from 'gatsby'
import LogoImage from '../images/logo-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'; // for accessing #layout-page-root
import styles from './navbar.module.scss';

export default class Navbar extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				ocMenuExpanded: false,
			}


			this.scrollHandler = () => {
				clearTimeout(this._tid);
				this._tid = setTimeout(() => this.updateOpacity(), 5);
			}
		}

		updateOpacity() {
			const el      = $(this.navbarRef),
				scrollTop = $(window).scrollTop(),
				scrollMax = el.height() * 3,
				perc      = scrollTop / scrollMax,
				opacity   = Math.max(0, Math.min(0.5, perc));

			el.css('background', `rgba(0,0,0,${opacity}`);
		}

		toggleMenu() {
			const newState = !this.state.ocMenuExpanded;
			this.setState({ ocMenuExpanded: newState });

			$('#layout-page-root')[newState ? 'addClass' : 'removeClass']('__ocMenuExpanded');
		}

		componentDidMount() {
			//console.log("[componentDidMount] ref:", this.navbarRef);
			$(window).on('scroll', this.scrollHandler);
		}

		componentWillUnmount() {
			// console.log("[navbar] componentWillUnmount")
			$(window).off('scroll', this.scrollHandler);
			clearTimeout(this._tid);
		}

		render() {
			const menu = (<>
				{/* {this.props.auth && <b>Logged in as {this.props.auth.user.email}!</b>} */}
				<Link to="/checkin"  state={{fromNavbar: true}}>Check In</Link>
				<Link to="/people"   state={{fromNavbar: true}}>People</Link>
				<Link to="/classes"  state={{fromNavbar: true}}>Classes</Link>

				<hr size="1"/>

				{this.props.auth  && <Link to="/login">Account</Link>}
				{!this.props.auth && <Link to="/login">Login</Link>}
			</>);

			return (<>
			<div ref={ref => this.navbarRef = ref }
				className={styles.navbar + " " + (this.state.ocMenuExpanded ? styles.navbarMenuExpanded : "")}>
				<div className={styles.navbarInner}>
					<LogoImage/>
					<div className={styles.navbarMenuWrap}>
						{menu}

						<button className={styles.menuTrigger + " hamburger--arrow hamburger hamburger--collapse " + (this.state.ocMenuExpanded ?  "is-active" : "")}
							onClick={() => this.toggleMenu()}
							type="button">
							<span className="hamburger-box">
								<span className="hamburger-inner"></span>
							</span>
						</button>
					</div>
				</div>
			</div>

			<div className={styles.ocMenu + " " + (this.state.ocMenuExpanded ? styles.ocMenuExpanded : "")}>
				<div className={styles.ocMenuInner} onClick={() => this.toggleMenu()}>
					{menu}
				</div>
			</div>
		</>)}
};



// export default Navbar;
