import { navigate as gatsbyNavigate } from 'gatsby';

import windowGlobal from './window-global';

function navigate() {
	if(!windowGlobal) {
		console.log("Rejecting navigate due to no windowGlobal");
	} else {
		const args = Array.from(arguments); /*global arguments*/
		gatsbyNavigate(...args);
	}
}

const navigateLater = (where) => {
	// const args = Array.from(arguments); /*global arguments*/
	setTimeout( () => navigate(where), 1);
}

export { navigate, navigateLater };
export default navigate;