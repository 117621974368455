import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navbar from '../navbar'
import './layout.scss';

import client from '../../utils/feathers';
import windowGlobal from '../../utils/window-global';
import { navigate } from '../../utils/navigate';

import versionChecker from '../../utils/version-checker';

import $ from 'jquery';

// Add icons we use elsewhere here for treeshaking and to import the SVG for use in the icon component
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faBars, faChevronDown, faChevronLeft, faSpinner, faMapPin, faPhone, faEnvelope, faCheckCircle, faSave, faCheck, faEdit,
	faExclamationCircle,
	faStar,
	faUserCheck,
	faExclamation,
	faPlus,
} from '@fortawesome/free-solid-svg-icons'
library.add(faTimes, faBars, faChevronDown, faSpinner, faMapPin, faPhone, faEnvelope, faCircle, faCheckCircle, faSave, faCheck, faChevronLeft, faEdit, 
	faExclamationCircle,
	faStar,
	faUserCheck,
	faExclamation,
	faPlus,
);


global.$ = $;
try { eval('window.jQuery = $;') } catch(e) {}

class Layout extends React.Component {
	state = { authenticated: false };

	constructor() {
		super();

		versionChecker.start();
		
		client.on('authenticated', this._authHandler = data => {
			try {
				if(this.unmounted)
					return;
					
				this.setState({
					...this.state,
					authenticated: data
				});
				// console.log("[layout.authenticated]  ", data);  

				if(this.props && this.props.authReturnTo) {
					if(windowGlobal) {
						navigate(this.props.authReturnTo);
					} else {
						console.log("No [windowGlobal], not redirecting to ", this.props.authReturnTo);
					}
				}
			}
			catch(e) {
				console.error("Error processing auth:", e);
			}
		});

		client.on('logout', this._logoutHandler = data => {
			if(this.unmounted)
				return;
				
			// console.log("[layout.logout]  ", data);
			this.setState({
				...this.state,
				authenticated: null
			});
		});

		client.authenticate().catch(error => {
			if(this.unmounted)
				return;

			this.setState({
				...this.state,
				authenticated: null
			});

			// console.log("[auth] props:", this.props);

			if(this.props && this.props.authRequired) {
				if(windowGlobal) {
					navigate('/login', { state: { from: this.props.page }});
				} else {
					console.log("No [windowGlobal], not redirecting to /login");
				}
			}
		});
	}

	componentWillUnmount() {
		this.unmounted = true;

		client.removeListener('logout', this._logoutHandler);
		client.removeListener('authenticated', this._authHandler);

		versionChecker.stop();
	}

	render() {
		return (
		<StaticQuery
			query={graphql`
				query SiteTitleQuery {
					site {
						siteMetadata {
							title
						}
					}
				}
			`}
			render={data => (
				<>
					<Helmet
						title={data.site.siteMetadata.title}
						meta={[
							{ name: 'description', content: 'Danceable App' },
							{ name: 'keywords', content: 'Dance, Checkin' },
						]}
					>
						<html lang="en" />
					</Helmet>
					<Navbar siteTitle={data.site.siteMetadata.title} page={this.props.page} auth={this.state.authenticated}/>
					<div className="page-wrap" id="layout-page-root">
						{typeof(this.props.children) === 'function' ? this.props.children(this.state.authenticated) : this.props.children}
					</div>
				</>
			)}
		/>
	)}
};

Layout.propTypes = {
	children: PropTypes.func.isRequired,
}

export default Layout;